<template>
  <div class="filter">
    <button v-on:click="onShowFilter" :class="['btn', 'btn-filter', 'btn-outline-primary', { collapsed: !showFilter }]">
      סינון מוצרים
    </button>

    <transition name="fade">
      <div class="filter-content" v-show="showFilter">
        <div class="filter-range-price">
          <label class="filter-title">מחיר</label>

          <el-slider v-model="rangePrice" range :min="29" :max="300" :show-tooltip="false"/>

          <div class="range-price-value">
            <p>
              <span class="shekel">₪</span>
              {{ rangePrice[0] }}
            </p>
            <p>
              <span class="shekel">₪</span>
              {{ rangePrice[1] }}
            </p>
          </div>
        </div>

        <div class="filter-colors">
          <p class="filter-title">צבע</p>

          <div class="filter-btns-color">
            <button
              v-for="(colorItem, index) of colors"
              :key="index"
              :class="['gong-color', {selectedColor: color ? colorItem.id === color.id : false}]"
              :title="colorItem.title"
              @click.prevent="selectColor(colorItem, $event)"
            >
              <span :style="getStyleColor(colorItem)"></span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css'
import { eventEmitter } from '@/main'

export default {
  name: 'FilterComponent',

  data() {
    return {
      showFilter: false,
    }
  },

  computed: {
    colors() {
      return this.$store.getters.colors
    },
    color() {
      return this.$store.getters.color
    },
    rangePrice: {
      get() {
        return this.$store.getters.price ? this.$store.getters.price : [29, 300]
      },
      set(newRangePrice) {
        if(newRangePrice[0] > 29 || newRangePrice[1] < 300) {
          this.$store.commit('setPrice', newRangePrice)
        }
      }
    },
  },

  methods: {
    getStyleColor(color) {
      return `background-color: #${color.color}; border-color: #${color.color === 'ffffff' ? 'd3d3d3' : color.color}`
    },

    onShowFilter() {
      this.showFilter = !this.showFilter
      eventEmitter.$emit('onShowFilter', this.showFilter)
    },

    selectColor(color, event) {
      if(event.target.parentNode.classList.contains('selectedColor')) {
        event.target.parentNode.classList.remove('selectedColor')
        this.$store.commit('setColor', null)
      } else {
        document.querySelectorAll('.gong-color').forEach(child => child.classList.remove('selectedColor'))
        event.target.parentNode.classList += ' selectedColor'
        this.$store.commit('setColor', color)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.range-price-value{
  display: flex;
  justify-content: space-between;
}
.range-price-value p{
  margin-bottom: 0;
}
.filter-btns-color{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.gong-color, .gong-color.selectedColor{
  margin-bottom: 1rem;
}

@include media-breakpoint-down(sm) {
  .filter{
    width: inherit;
    margin-left: 0;
    /*margin-top: -3.75rem;*/
    margin-top: 0;
  }
  #app .filter .btn-filter{
    font-size: .7rem;
  }
  .filter .btn-filter::after{
    font-size: 1.2rem;
  }
  .filter-colors{
    margin-bottom: 2rem;
  }
  .filter-btns-color{
    margin-bottom: 3rem;
  }
  #app .filter-btns-color .selectedColor:before{
    padding: .9rem;
    top: -.4rem;
    left: -.4rem;
  }
}
</style>
